<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>쿠폰</sub-title>
                <div class="board_list" style="padding:20px">
                    <div class="coupon">
                        <div class="item" @click="open('s')">
                            <img src="../../assets/images/coupon/silver.jpg" alt="">
                            <div class="btn-coupon btn-silver">
                                <div>
                                    OPEN ({{$store.state.userInfo.scoupon}}장)
                                </div>
                            </div>
                        </div>

                        <div class="item" @click="open('g')">
                            <img src="../../assets/images/coupon/gold.jpg" alt="">
                            <div class="btn-coupon btn-gold">
                                <div>
                                    OPEN ({{$store.state.userInfo.gcoupon}}장)
                                </div>

                            </div>


                        </div>

                        <div class="item" @click="open('d')">
                            <img src="../../assets/images/coupon/dia.jpg" alt="">
                            <div class="btn-coupon btn-diamond">
                                <div>
                                    OPEN ({{$store.state.userInfo.dcoupon}}장)
                                </div>
                            </div>

                        </div>
<!--                        <div class="item" @click="open('v')">-->
<!--                            <img src="../../assets/images/coupon/30.png" alt="">-->
<!--                            <div class="btn-coupon btn-diamond">-->
<!--                                <div>-->
<!--                                    OPEN ({{$store.state.userInfo.vcoupon}}장)-->
<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
                    </div>

                    <div class="culcheck_history_box">
                        <div>
                            <table class="utable">
                                <tr>
                                    <th colspan="2">쿠폰 획득내역</th>
                                </tr>
                                <tr>
                                    <th>시간</th>
                                    <th>내역</th>
                                </tr>
                                <tr v-for="(item,index) in couponGetHistory">
                                    <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>
                                    <td>{{item.content}}</td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <table class="utable">
                                <tr>
                                    <th colspan="2">쿠폰 사용내역</th>
                                </tr>
                                <tr>
                                    <th>시간</th>
                                    <th>내역</th>
                                </tr>
                                <tr v-for="(item,index) in couponUseHistory">
                                    <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>
                                    <td>{{item.content}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
        <mobile-foot-menus></mobile-foot-menus>
    </div>

</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import sportsConst from "../../common/sportsConst";
    import SubTitle from "../../components/SubTitle";
    import {getCouponConfig, getCouponHistory, openCoupon} from "../../network/leisureRequest";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import MobileFootMenus from "../../components/MobileFootMenus";

    export default {
        name: "Coupon",
        mixins: [postionMixin],
        components: {
            MobileFootMenus,
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                couponGetHistory: [],
                couponUseHistory: [],
                position: "쿠폰",
                config: null,
            }
        },
        methods: {
            open(type) {
                openCoupon(type).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: ' ' + res.data.data + '포인트 추가되였습니다',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$store.dispatch('actionUserInfo')
                        this.initHistory()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            initHistory() {
                getCouponHistory().then(res => {
                    if (res.data.success) {
                        this.couponGetHistory = res.data.data['getHistory']
                        this.couponUseHistory = res.data.data['useHistory']
                    }
                })
            },
            getCouponConfig() {
                getCouponConfig().then(res => {
                    if (res.data.success) {
                        this.config = res.data.data
                    }
                })
            }
        },
        created() {
            this.initHistory();
            //this.getCouponConfig()
        },
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    @import url("../../assets/css/culcheck.css");

    .coupon {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .coupon .item {
        width: 24.5%;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
    }

    .coupon .item img {
        width: 100%;
        max-width: 250px;
        --border-radius: 6px;
    }

    .coupon .item .btn-coupon {
        border-radius: 6px;
        margin-top: 10px;
        padding: 5px 20px;
        width: 250px;
        line-height: 34px;
        cursor: pointer;
    }

    .coupon .item .btn-silver {
        border: 1px solid silver;
        color: #eeeeee;
    }

    .coupon .item .btn-gold {
        border: 1px solid gold;
        color: #eeeeee;
    }

    .btn-diamond {
        border: 1px solid lightskyblue;
        color: #eeeeee;
    }

    @media screen and (max-width: 1024px) {
        .coupon .item {
            width: 99%;
            border-bottom: 2px solid #0b1423;
            padding: 10px;
        }
    }

</style>